<template>
  <div class="wrapper">
  <div  class="head_content">
   <!----> 
   <div  class="head_back"  v-on:click="goback()"  >

   <van-icon name="arrow-left" size=".2rem" color='#888888'  /> 

   </div> 
   <div class="head_text">回复反馈</div>
   
  </div>


<div class="news">


<van-field
  v-model="message"
  rows="4"
  autosize
  label="回复反馈"
  type="textarea" bel-width='60'
  placeholder="请输入内容"
/>
<van-field name="uploader" label="图片上传"  bel-width='60' >
  <template #input>
    <van-uploader  :max-count="1"  :after-read="afterRead"  v-model="uploader" />
  </template>
</van-field>


<van-button class='zcbtn' @click='tj' type="primary" round size="large" color='#1989fa'>提交</van-button>

</div>




<div v-for='(vo,index) in hflist' :key='index'>
<div class="hf" v-if='vo.uid!=null'>
    <div class="ren flex jcb">
     <span style="color:#333;">  <van-icon name="manager" /> 我</span> 
     <span style="font-size: 0.12rem;color:#333;"><van-icon name="clock-o" /> {{ vo.time }}</span>
    </div>
    <div class="nr">{{ vo.message }}</div>
    <div class="pic"><img :src="vo.pic" alt=""></div>
</div>

<div class="hf" v-else>
    <div class="ren flex jcb">
     <span>  <van-icon name="manager" /> 鉴承客服</span> 
     <span style="font-size: 0.12rem;color:#333;"><van-icon name="clock-o" /> {{ vo.time }}</span>
    </div>
    <div class="nr">{{ vo.message }}</div>

      <div class="pic" v-if='vo.pic!=null'><img :src='"http://admin.jianchenghr.com"+vo.pic' alt=""></div> 
</div>
</div>



<div class="h100"></div>






<foot></foot>
<div class="form_split_100"></div>
<bot cur='my'></bot>


  </div>
</template>

<script>
import Vue from 'vue';
import Foot from './../common/Foot.vue';
import Bot from './../common/Bot.vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import global from './../common.vue';

import { Form } from 'vant';
import { Field } from 'vant';
import { Uploader } from 'vant';
import { Toast } from 'vant';
Vue.use(Toast);
Vue.use(Uploader);
Vue.use(Form);
Vue.use(Field);

Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);
export default {
  name: 'Home',
     components:{
   Foot,
   Bot
    },data(){
      return {
hflist:[],
uploader:undefined,
message:'',
   id:null,
uid:localStorage.getItem('uid'),
      }
    },created(){
        var that=this;
            
           that.id=that.$route.query.id;
  this.$axios.post(global.http+'/api/hf',{id:that.id}).then((response) => {
 that.hflist=response.data;
    })


    },methods: {
   afterRead(file) {
       let that=this;
 let formData = new FormData();
     formData.append('flie', file.file);
      this.$axios({
      method: 'post',
      url: global.http+'/api/sc',
      data:formData,
       headers:{
                'Content-Type': 'multipart/form-data'
              }
              }).then((res) => {
that.uploader=global.http+res.data.url;
    })
    
    },

          goback:function(){
  this.$router.go(-1)
},
tz:function(){
 // this.$router.push('/detail');

},tj:function(){
  let that=this;
      if(that.message==''){
     Toast('内容不能为空');
     return false;
    }       

      this.$axios.post(global.http+'/api/lyhf',{
   
         message:that.message,
         pic:that.uploader,
         uid:that.uid,
         lid:that.id,
    
        
        }).then((res) => {


if(res.data.code==0){
 Toast(res.data.msg);
}else{
 Toast({
    message: res.data.msg,
   forbidClick: true,
   onClose:function(){
 that.$router.push('/user/wtlist')
   }
 }
 );

}

    })




}
    },

}



</script>


<style lang="less"  scoped>

.wrapper{
   max-width: 6.4rem;
    margin: 0 auto;
    padding:0rem 0rem;overflow: hidden;
        display: flex;
min-height: 100vh;
flex-direction: column;
padding-top: 0.4rem;
}
.banner{
width: 100%;
img{width: 100%;display: block;}
}
.b1{
    padding: 0rem;
    width: 100%;
    position: relative;
    height: 0.35rem;
    overflow: hidden;
    background: #fff url(/images/gonggao.png) .1rem no-repeat;
    background-size: .46rem ;
}
.b1 .item{
    padding: .1rem 0;
    font-size: .14rem;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.b1 ul{width: 80%;position: relative;left:.7rem;
li {
    padding: 0;
    margin: 0;
    line-height: 120%;
    list-style-type: none;
}
}

.b1 .more{
    position: absolute;
    right: -.2rem;
    top: .18rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: .14rem;
    color: #999;
    line-height: normal;
    padding: .026667rem .293333rem .026667rem 0;
}
.zwtit{padding: .1rem;margin-top: .1rem;
.more{
    color:#999;margin-top: 0.08rem;font-size: 0.14rem;
}
}
.zwtit span{font-size: 0.22rem;}

.hot{
li{padding: .1rem .16rem;padding-top: 0.2rem;
.z{width: 78%;}
.y{width: 22%;text-align: right;margin-top: 0.05rem;}
.tit{font-weight: bold;font-size: 0.16rem;margin-bottom: 0.06rem;}
.price{color:#ff5d24;font-weight: bold;font-size: 0.18rem;}
.time{color:#999999;margin-top: 0.02rem;}
}
margin-bottom: 0.4rem;
}

.head_content{position: fixed;top:0px;left:0px;width:100%;padding-top: 0.1rem;
border-bottom:1px solid #eee;padding-bottom: 0.1rem;
background: #fff;z-index: 10;
.head_back{margin-left: 0.05rem;position: absolute;z-index: 10;left:0rem}
.head_text{text-align: center;font-size: 0.18rem;position: relative;top:0.01rem;
color:#565656;
}
}

.news{
    padding: .2rem .2rem;padding-bottom: 0.5rem;
    li{border-bottom:1px solid #d9d9d9;padding-bottom: 0.06rem;margin-top: 0.15rem;
    .time{color:#999;font-size: 0.12rem;z-index: 1;}
    }

}

.hf{
    margin: 0px .2rem;border-bottom:1px solid #eee;margin-bottom:.2rem;
    .ren{border-bottom: 1px solid #eee;padding: 0.08rem 0.05rem;background: #eee;
    color:#f00;
    }
    .nr{padding:.1rem;padding-bottom: .2rem;}
}
.h100{height:.4rem;}
.pic{margin-bottom: 0.1rem;margin-left: 0.1rem;}
.pic img{max-width:2rem;border:1px solid #ddd;}
</style>
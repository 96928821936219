<template>
  <div class="wrapper">
<div class="top">
<div class="bt">会员中心</div>
<div class="tx auto"><img src="/images/tx.png" alt=""></div>
<div class="name" v-if='user'>{{ user.name }}</div>
<div class="tips" >工作单位：{{ qy }} <span v-if='qy==""'>无</span></div>
</div>
<div class="form_split1_10"></div>

<div class="menu" >
  <div  v-if='user'>
  <div class="li flex jcb" v-if='user.status==1'>
    <div class="lir" @click='tz("/user/wtlist")'>
      <span>问题反馈 <b v-if='num>0'>NEW</b> </span> <van-icon name="arrow" color='#ddd' />
    </div>
  </div>

  <div class="li li2 flex jcb" v-if='user.type=="在职用户"'>
    <div class="lir" @click='tz("/user/gzt")'>
      <span>我的工资条</span> <van-icon name="arrow" color='#ddd' />
    </div>
  </div>

    <div class="li li3 flex jcb">
    <div class="lir" @click='tz("/user/edit")'>
      <span>账号管理</span> <van-icon name="arrow" color='#ddd' />
    </div>
  </div>

    <div class="li li4 flex jcb" v-if='user.type=="企业用户"'>
    <div class="lir"  @click='tz("/user/yuangong")'>
      <span>我的员工</span> <van-icon name="arrow" color='#ddd' />
    </div>
  </div>
</div>
    <div class="li li5 flex jcb" @click='tc'>
    <div class="lir">
      <span>退出登录</span> <van-icon name="arrow" color='#ddd' />
    </div>
  </div>


</div>

<foot></foot>
<div class="form_split_100"></div>
<bot cur='my'></bot>


  </div>
</template>

<script>
import Vue from 'vue';
import Foot from './common/Foot.vue';
import Bot from './common/Bot.vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Toast } from 'vant';
import global from './common.vue';
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);
export default {
  name: 'Home',
     components:{
   Foot,
   Bot
    },data(){
      return{
user:null,
id:localStorage.getItem('uid'),
qy:'',
num:0
      }
    },created(){
         var that=this;

 this.$axios.get(global.http+'/api/new?uid='+that.id).then((res) => {
that.num=res.data.sl;

 })


   this.$axios.get(global.http+'/api/find?t=users&id='+that.id).then((response) => {
    that.user=response.data;

    localStorage.setItem('type',response.data.type);

if(response.data.qy!='' ||response.data.qy!=null ){
this.$axios.get(global.http+'/api/find?t=qy&id='+response.data.qy).then((response) => {

    that.qy=response.data.title;



    })
}else{
   that.qy="无";
}

    })

   

    },methods: {
      tc:function(){
localStorage.removeItem('uid');
localStorage.removeItem('user');
 Toast('已退出');
  this.$router.push('/');
      },
      tz:function(val){
          this.$router.push(val);
      }
    },

}



</script>


<style lang="less"  scoped>

.wrapper{
   max-width: 6.4rem;
    margin: 0 auto;
    padding:0rem 0rem;overflow: hidden;
        display: flex;
min-height: 100vh;
flex-direction: column;
}

.top{
background: #fff url(/images/bj.png) center -.5rem no-repeat;
    background-size: 5.4rem;
    height: 2.2rem;
    .bt{color:#fff;text-align: center;font-size: 0.19rem;font-weight: bold;padding-top: 0.15rem;}
.tx{width: .7rem;margin-top: 0.14rem;

img{width: 100%;
    -webkit-box-shadow: 0 0.026667rem 0.133333rem #c2c2c2;
    box-shadow: 0 0.026667rem 0.133333rem #c2c2c2;
border-radius: 100%;
}



}
.name{text-align: center;font-size: .2rem;font-weight: bold;color:#000;margin-top: 0.1rem;}
.tips{text-align: center;margin-top: 0.1rem;font-size: 0.12rem;color:#999;}


}


.menu{width: 80%;margin:0px auto;margin-top: 0.3rem;

.li{
background: url(/images/ico11.png) 0rem .22rem no-repeat;
background-size: .2rem .2rem;
padding-left: .4rem;

  .lir{width: 100%;border-bottom:1px solid #eee;display: flex;justify-content: space-between;
font-weight: bold;padding: .16rem .05rem;
  span{font-size: .16rem;
  b{color:#f00;font-size: 0.12rem;position: relative;top: -0.05rem;}
  }
.van-icon{position: relative;top:0rem}

  }
}

.li2{background-image:url(/images/ico12.png);background-size: .2rem .17rem;}
.li3{background-image:url(/images/ico13.png);}
.li4{background-image:url(/images/ico14.png);}
.li5{background-image:url(/images/ico16.png);}
.li5{background-size: .15rem .21rem;}

}
    


</style>
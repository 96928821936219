<template>
  <div class="wrapper">
  <div class="banner">
      <img src="/images/banner.jpg" alt="">
  </div>
<div class="form_split_10"></div>

<div  class="b1"  v-if='type=="在职用户"'>
    
   
      
      
       <div  class="more"> <router-link :to="'/news'"> 更多 <van-icon name="arrow" />
        </router-link></div></div>




<div class="gonggao"  v-if='type=="在职用户" || type=="企业用户" '>
 <ul  class="">
      <li  class="item flex jcb" v-for='(vo,index) in gglist' :key='index'>
<div>
        <router-link :to="'/xq?id='+vo.id">
      {{ vo.title }}
      </router-link>
</div>
      <div class="time">{{ vo.createtime }}</div>
      </li>
      
      </ul>

</div>



<div class="hot" v-if='type=="求职用户"'>
<div class="zwtit flex jcb">
   <span> 热门职位 </span>
    <router-link :to="'/list'">
    <div  class="more">更多 <van-icon name="arrow" /></div>
     </router-link>
</div>

<ul   v-for='(vo) in zw' :key='vo.id'>


    <li class="flex jcb" @click='tz(vo.id)'>
    <div class="z">
        <div class="tit">
{{ vo.title }}
        </div>
<div class="des">
{{ vo.glqy }}
</div>
    </div>
    <div class="y">
<div class="price">{{ vo.price }}</div>
<div class="time">{{ vo.time }}</div>
    </div>
    
    </li>
    <div class="form_split_10"></div>

    


</ul>


</div>

<foot></foot>
<div class="form_split_100"></div>
<bot cur='index'></bot>


  </div>
</template>

<script>
import Vue from 'vue';
import Foot from './common/Foot.vue';
import Bot from './common/Bot.vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import global from './common.vue';
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);
export default {
  name: 'Home',
     components:{
   Foot,
   Bot
    },data(){
return{
zw:null,
gglist:[],
type:'',
}
    },created(){
       document.title = '鉴承教育';
 if(localStorage.getItem('uid')==null && localStorage.getItem('user')==null){
  this.$router.push('/');
}
  var that=this;

     this.$axios.post(global.http+'/api/user',{uid:localStorage.getItem('uid')}).then((response) => {
    that.type=response.data.type;
    localStorage.setItem('type',response.data.type);
    })


        this.$axios.get(global.http+'/api/zw').then((response) => {
    that.zw=response.data;
    })

      this.$axios.post(global.http+'/api/gg',{uid:localStorage.getItem('uid')}).then((response) => {
 that.gglist=response.data;
    })


    },methods: {
      tz:function(val){
  this.$router.push('/detail?id='+val);

      }
    },

}



</script>


<style lang="less"  scoped>

.wrapper{
   max-width: 6.4rem;
    margin: 0 auto;
    padding:0rem 0rem;overflow: hidden;
        display: flex;
min-height: 100vh;
flex-direction: column;
}
.banner{
width: 100%;
img{width: 100%;display: block;}
}
.b1{
    padding: 0rem;
    width: 100%;
    position: relative;
    height: 0.35rem;
    overflow: hidden;
    background: #fff url(/images/gonggao.png) .1rem no-repeat;
    background-size: .46rem ;
}
.b1 .item{
    padding: .1rem 0;
    font-size: .14rem;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.b1  a{color:#333;}
.b1 ul{width: 80%;position: relative;left:.7rem;
li {
    padding: 0;
    margin: 0;
    line-height: 120%;
    list-style-type: none;
}
}

.b1 .more{
    position: absolute;
    right: -.2rem;
    top: .18rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: .14rem;
    a{ color: #999;}
   
    line-height: normal;
    padding: .026667rem .293333rem .026667rem 0;
}
.zwtit{padding: .1rem;margin-top: .1rem;
.more{

    color:#999;margin-top: 0.08rem;font-size: 0.14rem;
}
}
.zwtit span{font-size: 0.22rem;}

.hot{
li{padding: .1rem .16rem;padding-top: 0.2rem;
.z{width: 70%;}
.y{width: 30%;text-align: right;margin-top: 0.05rem;}
.tit{font-weight: bold;font-size: 0.18rem;margin-bottom: 0.06rem;}
.price{color:#ff5d24;font-weight: bold;font-size: 0.18rem;}
.time{color:#999999;margin-top: 0.02rem;}
}
margin-bottom: 0.4rem;
}
.gonggao{
  margin-top: 0.03rem;padding-bottom: .5rem;
  padding:.1rem .1rem;
  li{margin-bottom: 0.05rem;font-size: 0.16rem;border-bottom:0.02rem solid #eee;
  padding-bottom: 0.04rem;
  }
a{font-size: .17rem;color:#333;font-weight: bold;}
.time{padding-right: 0.1rem;font-size: 0.14rem;}
}

</style>
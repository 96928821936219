<template>
  <div class="log_wrapper">



  <div  class="head_content" style="display:none">
   <!----> 

   <div class="head_text">商务合作</div>
  </div>








    <div class="logo auto"><img src="/images/logo.png" alt=""></div>





<div class="zc" >

<div class="tx1" style="margin-left: 0.2rem;">欢迎合作交流</div>


<van-form >
  <!-- 通过 pattern 进行正则校验 -->
  <van-field
    v-model="qy"
    name="qy"  label="公司全称"
    placeholder="请输入公司全称"
  
  />
  <!-- 通过 validator 进行函数校验 -->
  <van-field
    v-model="lxr"
    name="lxr" label="联系人"
    placeholder="请输入联系人"
 
  />

 <van-field
    v-model="lxdh"
    name="lxdh" label="联系电话"
    placeholder="请输入联系电话"

  />


<van-field name="hzms" label="合作模式">
  <template #input>
    <van-checkbox-group v-model="hzms" direction="horizontal">
      <van-checkbox name="社会招聘" shape="square">社会招聘</van-checkbox>
      <van-checkbox name="校企合作" shape="square">校企合作</van-checkbox>
    </van-checkbox-group>
  </template>
</van-field>

  <!-- 通过 validator 进行异步函数校验 -->
<van-field
  v-model="zpyq"
  rows="5"
  autosize
  label="招聘要求"
  type="textarea"
  placeholder="请输入招聘要求"
/>
  <div style="margin: 16px;">
    <van-button round block type="info"  @click="sub" native-type="submit">提交</van-button>
  </div>
</van-form>




</div>

<foot></foot>


  </div>
</template>

<script>
import Foot from './common/Foot.vue';
import Vue from 'vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import global from './common.vue';
import { Toast } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);

export default {
  name: 'hezuo',
   data() {
        return {
          test:'111',
     checkbox: false,
      hzms: [],
      qy:'',
      lxr:'',
      lxdh:'',
      zpyq:'',
      subtj:0,
        }
    },
     components:{
   Foot
    },created(){

 document.title = '商务合作-鉴承教育';
  var that=this;




    },methods: {

tcxl:function(){

  this.showPicker=1;
},
tcqy:function(){

  this.showPicker1=1;
},
    onConfirm(value) {
     
 this.xueli=value;
 this.showPicker=0;
    },
    onConfirm1(value) {

 this.qy=value;
 this.showPicker1=0;
    },

    onChange(value) {
  console.log(value+'1');
    },
    onCancel() {
       this.showPicker=0;
    },
      onCancel1() {
       this.showPicker1=0;
    },
    sub(){
        var that=this;
         var phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
   if(that.qy==''){
     Toast('请输入公司名称');

     return false;
      }
 if(that.lxr==''){
     Toast('请输入联系人');

     return false;
      }

    if(!phoneReg.test(that.lxdh)){
           Toast('请正确输入联系电话');
              return false;
      }
 
  if(that.hzms.length==0){
     Toast('请选择合作模式');
     return false;
    }

 
  if(that.zpyq==''){
     Toast('请输入招聘要求');
     return false;
    }



if(that.subtj==0){
that.subtj=1;

        this.$axios.post(global.http+'/api/hezuo',{
   
         lxr:that.lxr,
         qy:that.qy,
         lxdh:that.lxdh,
         hzms:that.hzms,
         zpyq:that.zpyq,
     
        
        }).then((res) => {

  console.log(res.data);

 Toast(res.data.msg);


    })
}else{
  Toast('您已提交,请勿重复提交');
}


    }




    },

}



</script>


<style lang="less"  scoped>

.van-checkbox--horizontal {
    margin-right: 8px;
}
.log_wrapper{
   max-width:5.8rem;
    margin: 0 auto;
    padding:0rem 0rem;overflow: hidden;
        display: flex;
min-height: 100vh;
flex-direction: column;
}

.head_reg_txt{font-size: 0.15rem;}

.tx1{
    padding-top: .3rem;

    font-size: .24rem;
    color: #333;margin-bottom: 0.2rem;
}
.logo{width:2.4rem;padding-top: 0rem;
img{width: 100%;padding-top: 0.3rem;}
}

.field_cell{
    width: 100%;
    border-bottom: .01rem solid #eee;
    position: relative;
    padding-top: .133333rem;
}

.log_field{
    border: 0;
    width: 100%;
    display: block;
    color: #333;
    font-size: .16rem;
    padding: .12rem 0;
}

.dl{margin-top: 0.3rem;}
.dl1{margin-top: 0.5rem;}
.tip{
  text-align: center;
  margin-top: 0.15rem;margin-bottom: 1rem;
  color:#a6a6a7;
a{color:#444;}
}

.head_content{position: relative;top:0px;left:0px;max-width:100%;padding-top: 0.1rem;
border-bottom:0px solid #eee;padding-bottom: 0.1rem;
.head_back{margin-left: 0.05rem;position: absolute;z-index: 10;left:-.2rem}
.head_text{text-align: center;font-size: 0.18rem;position: relative;top:0.01rem;
color:#565656;
}

}
.zcbtn{margin-top: 0.4rem;}

</style>
<template>
  <div class="wrapper">
  <div  class="head_content">
   <!----> 
   <div  class="head_back"  v-on:click="goback()"  >

   <van-icon name="arrow-left" size=".2rem" color='#888888'  /> 

   </div> 
   <div class="head_text">关于我们</div>
  </div>


<div class="about" v-html='nr'>
  
</div>


<foot></foot>
<div class="form_split_100"></div>
<bot cur='about'></bot>


  </div>
</template>

<script>
import Vue from 'vue';
import Foot from './common/Foot.vue';
import Bot from './common/Bot.vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import global from './common.vue';
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);
export default {
  name: 'Home',
     components:{
   Foot,
   Bot
    },data(){
return{
 nr:'',

}
    },created(){
    var that=this;
            
        
  this.$axios.post(global.http+'/api/about').then((response) => {
 that.nr=response.data;
    })



    },methods: {
          goback:function(){
  this.$router.go(-1)
},
    },

}



</script>


<style lang="less"  scoped>

.wrapper{
   max-width: 6.4rem;
    margin: 0 auto;
    padding:0rem 0rem;overflow: hidden;
        display: flex;
min-height: 94vh;
flex-direction: column;
padding-top: 0.5rem;
}
.banner{
width: 100%;
img{width: 100%;display: block;}
}
.b1{
    padding: 0rem;
    width: 100%;
    position: relative;
    height: 0.35rem;
    overflow: hidden;
    background: #fff url(/images/gonggao.png) .1rem no-repeat;
    background-size: .46rem ;
}
.b1 .item{
    padding: .1rem 0;
    font-size: .14rem;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.b1 ul{width: 80%;position: relative;left:.7rem;
li {
    padding: 0;
    margin: 0;
    line-height: 120%;
    list-style-type: none;
}
}

.b1 .more{
    position: absolute;
    right: -.2rem;
    top: .18rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: .14rem;
    color: #999;
    line-height: normal;
    padding: .026667rem .293333rem .026667rem 0;
}
.zwtit{padding: .1rem;margin-top: .1rem;
.more{
    color:#999;margin-top: 0.08rem;font-size: 0.14rem;
}
}
.zwtit span{font-size: 0.22rem;}

.hot{
li{padding: .1rem .16rem;padding-top: 0.2rem;
.z{width: 78%;}
.y{width: 22%;text-align: right;margin-top: 0.05rem;}
.tit{font-weight: bold;font-size: 0.16rem;margin-bottom: 0.06rem;}
.price{color:#ff5d24;font-weight: bold;font-size: 0.18rem;}
.time{color:#999999;margin-top: 0.02rem;}
}
margin-bottom: 0.4rem;
}

.head_content{position: fixed;top:0px;left:0px;width:100%;padding-top: 0.1rem;
border-bottom:1px solid #eee;padding-bottom: 0.1rem;
background: #fff;
.head_back{margin-left: 0.05rem;position: absolute;z-index: 10;left:0rem}
.head_text{text-align: center;font-size: 0.18rem;position: relative;top:0.01rem;
color:#565656;
}
}

.about{ line-height: 170%;
    padding: .1rem .15rem;
    p{margin-bottom: 0.1rem;}
img{width:100%;}

}

</style>
<template>
  <div class="log_wrapper">



  <div  class="head_content">
   <!----> 
   <div  class="head_back"  v-on:click="goback()"  >

   <van-icon name="arrow-left" size=".2rem" color='#888888'  /> 

   </div> 
   <div class="head_text">用户注册</div>
  </div>








    <div class="logo auto"><img src="/images/logo.png" alt=""></div>




<div class="usertype" v-if='type==undefined'>
<van-button class='dl dl1' @click='usertype(1)' type="primary" round plain size="large" color='#1989fa'>在职者用户注册</van-button>


<van-button class='dl' @click='usertype(2)' type="primary" round plain size="large" color='#1989fa'>求职者用户注册</van-button>



<van-button class='dl' @click='usertype(3)' type="primary" round plain size="large" color='#1989fa'>企业用户注册</van-button>
</div>


<div class="zc" v-if='type!=undefined'>

<div class="tx1">欢迎注册{{ typename }}</div>


  <div  class="field_cell_group">
   <div  class="field_cell">
    <label ><input  type="text" v-model='user' placeholder="请输入手机号" autocomplete="off" class="log_field" /></label>
   </div> 
   <div  class="field_cell">
    <label ><input  placeholder="账户密码" v-model='psw' autocomplete="off" type="password" class="log_field" /></label> 
   </div> 
  <div  class="field_cell">
    <label ><input  placeholder="确认密码" v-model='psw1' autocomplete="off" type="password" class="log_field" /></label> 
   </div> 
<div  class="field_cell">
    <label ><input  type="text" v-model='name' placeholder="姓名" autocomplete="off" class="log_field" /></label>
   </div>
   <div  class="field_cell" v-if='type==1||type==2'>
    <label ><input  type="text" placeholder="身份证号" v-model='sfz' autocomplete="off" class="log_field" /></label>
   </div>
   <div  class="field_cell" v-if='type==1||type==2'>
    <label ><input @click='tcxl' v-model="xueli"  type="text" placeholder="选择学历" autocomplete="off" class="log_field" />
  <span style="position:absolute;right:0px;top:.25rem"><van-icon name="arrow" size=".2rem" 
  color='#999'
   /></span>
    </label>
   </div>

 <div  class="field_cell" v-if='type==1||type==3'>
    <label ><input @click='tcqy' v-model="qy"  type="text" placeholder="选择工作单位" autocomplete="off" class="log_field" />
  <span style="position:absolute;right:0px;top:.25rem"><van-icon name="arrow" size=".2rem" 
  color='#999'
   /></span>
    </label>
   </div>


<van-popup v-model="showPicker" position="bottom">
  <van-picker
    show-toolbar
    :columns="columns"
    @confirm="onConfirm"
    @cancel="showPicker = false"
  />
</van-popup>


<van-popup v-model="showPicker1" position="bottom">
  <van-picker
    show-toolbar
    :columns="columns1"
    @confirm="onConfirm1"
    @cancel="showPicker1 = false"
  />
</van-popup>
   

   <!----> 
   <!---->
  </div> 

<van-button @click='sub' class='zcbtn' type="primary" round size="large" color='#1989fa'>注册{{ typename }}</van-button>

<div class="tip">已有账号？
  <router-link :to="'/'">立即登录
 </router-link>
 </div>

</div>

<foot></foot>


  </div>
</template>

<script>
import Foot from './common/Foot.vue';
import Vue from 'vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import global from './common.vue';
import { Toast } from 'vant';
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);

export default {
  name: 'Reg',
   data() {
        return {
user:'',
psw:'',
psw1:'',
name:'',
sfz:'',
  type:null,
  typename:null,
      columns: ['初中及以下','中专','高中','大专', '本科', '研究生及以上',],
      columns1: [],
      showPicker: false,
      showPicker1: false,
      xueli:'',
      qy:''
        }
    },
     components:{
   Foot
    },created(){

 document.title = '用户注册-鉴承教育';
  var that=this;

   this.$axios.get(global.http+'/api/qy').then((response) => {
    that.columns1=response.data;
    })



    },methods: {
  goback:function(){
  this.$router.go(-1)
},
usertype:function(val){
this.type=val;
if(val==1){
  this.typename='在职者用户'
}
if(val==2){
  this.typename='求职者用户'
}
if(val==3){
  this.typename='企业用户'
}


},
tcxl:function(){

  this.showPicker=1;
},
tcqy:function(){

  this.showPicker1=1;
},
    onConfirm(value) {
     
 this.xueli=value;
 this.showPicker=0;
    },
    onConfirm1(value) {

 this.qy=value;
 this.showPicker1=0;
    },

    onChange(value) {
  console.log(value+'1');
    },
    onCancel() {
       this.showPicker=0;
    },
      onCancel1() {
       this.showPicker1=0;
    },
    sub(){
        var that=this;
         var phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
   if(that.user==''){
     Toast('请输入手机号');

     return false;
      }
    if(!phoneReg.test(that.user)){
           Toast('请正确输入手机号');
              return false;
      }
  if(that.psw==''){
     Toast('请输入密码');
     return false;
    }
  if(that.psw1==''){
     Toast('请输入确认密码');
     return false;
    }

  if(that.psw!=that.psw1){
     Toast('两次密码不一致');
     return false;
    }
  if(that.name==''){
     Toast('请输入姓名');
     return false;
    }

 if(that.type==1 || that.type==2){
      if(that.sfz==''){
     Toast('请输入身份证');
     return false;
    }
if(!that.sfz || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(that.sfz)){
                  Toast('请正确输入身份证');
     return false;
             }
     
      if(that.xueli==''){
     Toast('请选择学历');
     return false;
    }       
 }


    if(that.type==1 || that.type==3){
 if(that.qy==''){
     Toast('请选择工作单位');
     return false;
    }  

    }



        this.$axios.post(global.http+'/api/reg.php',{
        params:{
         user:that.user,
         name:that.name,
         psw:that.psw,
         sfz:that.sfz,
         xueli:that.xueli,
         type:that.type,
         qy:that.qy,
        }
        }).then((res) => {

  console.log(res.data);
if(res.data.code==0){
 Toast('手机号已注册！');
}else{
 Toast({
    message: '注册成功',
   forbidClick: true,
   onClose:function(){
  that.$router.push('/')
   }
 }
 );

}

    })



    }




    },

}



</script>


<style lang="less"  scoped>
.log_wrapper{
   max-width:5.8rem;
    margin: 0 auto;
    padding:0rem .3rem;overflow: hidden;
        display: flex;
min-height: 100vh;
flex-direction: column;
}

.head_reg_txt{font-size: 0.15rem;}

.tx1{
    padding-top: .4rem;

    font-size: .24rem;
    color: #333;margin-bottom: 0.2rem;
}
.logo{width:2.4rem;padding-top: 0rem;
img{width: 100%;padding-top: 0.3rem;}
}

.field_cell{
    width: 100%;
    border-bottom: .01rem solid #eee;
    position: relative;
    padding-top: .133333rem;
}

.log_field{
    border: 0;
    width: 100%;
    display: block;
    color: #333;
    font-size: .16rem;
    padding: .12rem 0;
}

.dl{margin-top: 0.3rem;}
.dl1{margin-top: 0.5rem;}
.tip{
  text-align: center;
  margin-top: 0.15rem;margin-bottom: 1rem;
  color:#a6a6a7;
a{color:#444;}
}

.head_content{position: relative;top:0px;left:0px;max-width:100%;padding-top: 0.1rem;
border-bottom:0px solid #eee;padding-bottom: 0.1rem;
.head_back{margin-left: 0.05rem;position: absolute;z-index: 10;left:-.2rem}
.head_text{text-align: center;font-size: 0.18rem;position: relative;top:0.01rem;
color:#565656;
}

}
.zcbtn{margin-top: 0.4rem;}

</style>
<template>

  

<div class="bottom flex">
    <div   v-bind:class="(cur=='index')?'li cur':'li'">
         <router-link :to="'/index'">
<van-icon name="wap-home" size='.24rem' />

<span>首页</span> 
 </router-link>
    </div>
    <div  v-if='type=="求职用户"'  v-bind:class="(cur=='list')?'li cur':'li'">
         <router-link :to="'/list'">
<van-icon name="balance-list" size='.24rem'/>
<span>职位</span> 
 </router-link>
    </div>
    <div   v-bind:class="(cur=='about')?'li cur':'li'">
           <router-link :to="'/about'">
<van-icon name="column" size='.24rem' />
<span>关于</span> 
 </router-link>
    </div>
    <div   v-bind:class="(cur=='my')?'li cur':'li'">
 <router-link :to="'/my'">
<van-icon name="manager" size='.24rem' />
<span>我的</span> 
 </router-link>
    </div>


</div>
</template>

<script>

export default {
  name: 'Bot',
    data() {
          return {
              type:0,
          }
      }
,created(){
if(localStorage.getItem('type')!=undefined){
this.type=localStorage.getItem('type');
}
},
      props: {
    cur: String
  }
}



</script>


<style lang="less">
.bottom{
    border-top: 1px solid #ddd;
    position: fixed;bottom: 0px;width:100%;background: #fff;left:0%;
    justify-content: space-around;
    text-align: center;padding: .07rem 0rem;padding-bottom: 0.05rem;
    .li{width: 25%;cursor: pointer;}

    span{display: block;font-size: 0.14rem;color:#999;margin-top: 0.02rem;}
    .van-icon{color:#999}
    .cur{
          .van-icon{color:#007dff}
 span{color:#007dff;font-weight: bold;}
   
}

}
</style>
<template>
  <div class="wrapper">
  <div  class="head_content">
   <!----> 
   <div  class="head_back"  v-on:click="goback()"  >

   <van-icon name="arrow-left" size=".2rem" color='#888888'  /> 

   </div> 
   <div class="head_text">职位详情</div>
  </div>



<div class="zwjj flex jcb">
    <div class="zwz">
<span>{{ info.title }}</span>
<p>  招聘人数：{{ info.tag }}人</p>

    </div>
    <div class="zwy">
<span>{{ info.price }}</span>
<p>发布时间：{{ info.createtime }}</p>
    </div>
   
</div>
 <div class="gzdd">
<h1><van-icon name="location" />{{ info.gzd }}</h1>
 </div>

 <div class="form_split_10"></div>

<div class="gwdy pd10">
    <div class="tit"> 职位介绍</div>
<div class="dy" v-html='info.gwdy'>
    
</div>

</div>
 <div class="form_split_10"></div>


<!--
<div class="gwdy pd10">
    <div class="tit"> 岗位说明</div>
<div class="dy" v-html='info.gwsm'>
  
</div>

</div>
-->


 <div class="form_split_10"></div>

<div class="gwdy pd10 qy">
    <div class="tit">企业信息</div>
<div class="dy">
    <div class="qyjj flex">
<img :src="http+info.glqy.thumb" alt="" class="qylogo">
        <div>
<div class="js1">{{ info.glqy.title }}</div>
<div class="des">{{ info.glqy.tag }}</div> 
</div>
</div>
<div class="des1" v-html="info.glqy.des">

</div>
</div>

</div>
 <div class="form_split_10"></div>
<div class="gwdy qy">
    <div class="tit pd10"  style="margin-bottom:0px;">其他推荐</div>


<div class="hot">


<ul v-for='(vo,index) in tuijian' :key='vo.index'>
    <li class="flex jcb" @click='tz(vo.id)'>
    <div class="z">
        <div class="tit">
         {{ vo.title }}
        </div>
<div class="des">
{{ vo.glqy }}
</div>
    </div>
    <div class="y">
<div class="price">{{ vo.price }}</div>
<div class="time">{{ vo.createtime }}</div>
    </div>
    </li>
    <div class="form_split_10"></div>
</ul>


</div>






</div>



<foot></foot>
<div class="form_split_100"></div>

<div class="bar flex">

    <div class="bar3" @click='qz()'> 立即报名</div>

</div>

  </div>
</template>

<script>
import Vue from 'vue';
import Foot from './common/Foot.vue';
import Bot from './common/Bot.vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import global from './common.vue';
import { Toast } from 'vant';
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);
export default {
  name: 'Home',
  data(){
      return {
id:null,
info:null,
http:global.http,
tuijian:null,
text:'提交求职'
      }
  },
     components:{
   Foot,
   Bot
    },created(){
         document.title = '职位详情-鉴承教育';
  var that=this;
     that.id=that.$route.query.id;
        this.$axios.get(global.http+'/api/find?t=zw&id='+that.id).then((response) => {
    that.info=response.data;
    })

        this.$axios.get(global.http+'/api/list?t=zw&limit=10').then((response) => {
    that.tuijian=response.data;
    })

    },methods: {
          goback:function(){
  this.$router.go(-1)
},tz:function(val){
 var that=this;
        this.$axios.get(global.http+'/api/find?t=zw&id='+val).then((response) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
    that.info=response.data;
    })

},qz:function(){
     var that=this;
    this.$axios.post(global.http+'/api/qz',{uid:localStorage.getItem('uid'),zwid: that.id}).then((res) => {
  
 Toast({
    message: res.data.msg,
   forbidClick: true,
   onClose:function(){
 that.text='已申请'
   }
 }
 );



    })



}
    },

}



</script>


<style lang="less"  scoped>

.wrapper{
   max-width: 6.4rem;
    margin: 0 auto;
    padding:0rem 0rem;overflow: hidden;
        display: flex;
min-height: 100vh;
flex-direction: column;
padding-top: 0.4rem;
}
.banner{
width: 100%;
img{width: 100%;display: block;}
}
.b1{
    padding: 0rem;
    width: 100%;
    position: relative;
    height: 0.35rem;
    overflow: hidden;
    background: #fff url(/images/gonggao.png) .1rem no-repeat;
    background-size: .46rem ;
}
.b1 .item{
    padding: .1rem 0;
    font-size: .14rem;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.b1 ul{width: 80%;position: relative;left:.7rem;
li {
    padding: 0;
    margin: 0;
    line-height: 120%;
    list-style-type: none;
}
}

.b1 .more{
    position: absolute;
    right: -.2rem;
    top: .18rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: .14rem;
    color: #999;
    line-height: normal;
    padding: .026667rem .293333rem .026667rem 0;
}
.zwtit{padding: .1rem;margin-top: .1rem;
.more{
    color:#999;margin-top: 0.08rem;font-size: 0.14rem;
}
}
.zwtit span{font-size: 0.22rem;}

.hot{
li{padding: .1rem .16rem;padding-top: 0.2rem;
.z{width: 70%;}
.y{width: 30%;text-align: right;margin-top: 0.05rem;}
.tit{font-weight: bold;font-size: 0.16rem;margin-bottom: 0.06rem;}
.price{color:#ff5d24;font-weight: bold;font-size: 0.18rem;}
.time{color:#999999;margin-top: 0.02rem;}
}
margin-bottom: 0.4rem;
}

.head_content{position: fixed;top:0px;left:0px;width:100%;padding-top: 0.1rem;
border-bottom:1px solid #eee;padding-bottom: 0.1rem;
background: #fff;
.head_back{margin-left: 0.05rem;position: absolute;z-index: 10;left:0rem}
.head_text{text-align: center;font-size: 0.18rem;position: relative;top:0.01rem;
color:#565656;
}
}
.pd10{padding: 0px .2rem;margin-top: 0.2rem;}
.zwjj{
    margin-top: 0.2rem;padding: 0px .2rem;
.zwz{
    span{font-weight: bold;font-size: 0.19rem;margin-bottom: 0.1rem;display: block;}
}
.zwy{
    text-align: right;
    span{
       font-weight: bold;font-size: 0.19rem;color:#ff5d24 ;margin-bottom: 0.1rem;display: block;
    }
}
}
.gzdd{
        margin-top: 0.13rem;padding: 0px .16rem;margin-bottom: 0.1rem;
    h1{font-size: .13rem;margin-bottom: 0.04rem;color:#333;font-weight: normal;
    .van-icon{color:#1787fb;}
    }

}

.gwdy{
line-height: 170%;margin-bottom: 0.1rem;
       .tit{font-weight: bold;font-size: 0.18rem;margin-bottom: 0.1rem;}
}

.qy{
    .tit{margin-bottom: 0.2rem;}
    .qyjj{    align-items: center;margin-bottom: 0.1rem;}
    .qylogo{width: 0.5rem;border-radius: 100%;margin-right: 0.15rem;}
    .js1{font-weight: bold;font-size: 0.16rem;}
    .des{color:#777;}
    .des1{font-size: 0.14rem;line-height:.25rem;}
}

.bar{
    height: 0.5rem;width: 100%;align-items: center;position: fixed;bottom:0px;
    background: #fff;
    .bar1,.bar2{width: 17%;text-align: center;
    .van-icon{font-size: 0.22rem;}
    span{display: block;font-size: 0.12rem;}
    }
    .bar3{text-align: center;width: 100%;font-size: 0.18rem;color:#fff;
    background: #1787fb;height: 100%;line-height: 0.5rem;}
}
</style>
<template>
  <div class="wrapper">
  <div  class="head_content">
   <!----> 
   <div  class="head_back"  v-on:click="goback()"  >

   <van-icon name="arrow-left" size=".2rem" color='#888888'  /> 

   </div> 
   <div class="head_text">资料编辑</div>
  </div>


<div class="about" v-if='user'>
  <p style="margin-top:.3rem;font-size:.16rem;font-weight:bold;">基本资料</p>
  <div  class="field_cell_group">
<div  class="field_cell">
    <label ><input  type="text" v-model='user.name' placeholder="姓名" autocomplete="off" class="log_field"  readonly="readonly" /></label>
   </div>

   <div  class="field_cell">
    <label ><input  type="text" v-model='user.user' placeholder="请输入手机号" autocomplete="off" class="log_field" /></label>
   </div> 

   <div  class="field_cell">
    <label ><input  type="text" placeholder="身份证号" v-model='user.sfz' autocomplete="off" class="log_field"  /></label>
   </div>
   <div  class="field_cell">
    <label ><input @click='tcxl' v-model="xueli"  type="text" placeholder="选择学历" autocomplete="off" class="log_field" />
  <span style="position:absolute;right:0px;top:.25rem"><van-icon name="arrow" size=".2rem" 
  color='#999'
   /></span>
    </label>

   </div>

<p style="margin-top:.3rem;font-size:.16rem;font-weight:bold;">修改密码</p>
   
   <div  class="field_cell">
    <label ><input  placeholder="输入修改密码" autocomplete="off"  v-model='psw' type="password" class="log_field" /></label> 
   </div> 
  <div  class="field_cell">
    <label ><input  placeholder="确认修改密码"  autocomplete="off"  v-model='psw1' type="password" class="log_field" /></label> 
   </div> 

<van-popup v-model="showPicker" position="bottom">
  <van-picker
    show-toolbar
    :columns="columns"
    @confirm="onConfirm"
    @cancel="showPicker = false"
  />
</van-popup>
   

   <!----> 
   <!---->
  </div> 

<van-button @click='sub' class='zcbtn' type="primary" round size="large" color='#1989fa'>修改</van-button>

</div>


<foot></foot>
<div class="form_split_100"></div>
<bot cur='my'></bot>


  </div>
</template>

<script>
import Vue from 'vue';
import Foot from './../common/Foot.vue';
import Bot from './../common/Bot.vue';
import { Button } from 'vant';
import { Icon } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import global from './../common.vue';
import { Toast } from 'vant';
Vue.use(Toast);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Icon);
Vue.use(Button);

export default {
  name: 'Home',
     components:{
   Foot,
   Bot
    },data(){
        return{
               columns: ['大专', '本科', '研究生', '博士'],
      showPicker: false,
      xueli:'',
      user:null,
         psw:'',
   psw1:'',
      id:localStorage.getItem('uid'),
        }
    },created(){
              var that=this;
   this.$axios.get(global.http+'/api/find?t=users&id='+that.id).then((response) => {
    that.user=response.data;
    that.xueli=response.data.xueli;
    })
    }
    
    ,methods: {
          goback:function(){
  this.$router.go(-1)
},tcxl:function(){

  this.showPicker=1;
}, onConfirm(value) {
     
 this.xueli=value;
 this.showPicker=0;
    },
    onChange(value) {
  console.log(value+'1');
    },
    onCancel() {
       this.showPicker=0;
    },sub(){
     var that=this;
         var phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
   if(that.user.user==''){
     Toast('请输入手机号');
     return false;
      }
        if(!phoneReg.test(that.user.user)){
           Toast('请正确输入手机号');
      }


      if(that.user.sfz==''){
     Toast('请输入身份证');
     return false;
    }
if(!that.user.sfz || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(that.user.sfz)){
                  Toast('请正确输入身份证');
     return false;
             }
     
      if(that.user.xueli==''){
     Toast('请选择学历');
     return false;
    }       

     if(that.psw!=''){
  if(that.psw!=that.psw1){
     Toast('两次密码不一致');
     return false;
    }else{
         


        this.$axios.post(global.http+'/api/edit.php',{
        params:{
         user:that.user.user,
         sfz:that.user.sfz,
         xueli:that.xueli,
         id:that.id,
         psw:that.psw,
        }
        }).then((res) => {

  console.log(res.data);
if(res.data.code==0){
 Toast(res.data.msg);
}else{
 Toast({
    message: res.data.msg,
   forbidClick: true,
   onClose:function(){
 that.$router.push('/my')
   }
 }
 );

}

    })


    }

     }else{




        this.$axios.post(global.http+'/api/edit.php',{
        params:{
         user:that.user.user,
         sfz:that.user.sfz,
         xueli:that.xueli,
         id:that.id,
         psw:that.user.psw,
        }
        }).then((res) => {

  console.log(res.data);
if(res.data.code==0){
 Toast(res.data.msg);
}else{
 Toast({
    message: res.data.msg,
   forbidClick: true,
   onClose:function(){
 that.$router.push('/my')
   }
 }
 );

}

    })








     }






    }




    },

}
</script>


<style lang="less"  scoped>

.wrapper{
   max-width: 6.4rem;
    margin: 0 auto;
    padding:0rem 0rem;overflow: hidden;
        display: flex;
min-height: 94vh;
flex-direction: column;
padding-top: 0.5rem;
}

.head_content{position: fixed;top:0px;left:0px;width:100%;padding-top: 0.1rem;
border-bottom:1px solid #eee;padding-bottom: 0.1rem;
background: #fff;
.head_back{margin-left: 0.05rem;position: absolute;z-index: 10;left:0rem}
.head_text{text-align: center;font-size: 0.18rem;position: relative;top:0.01rem;
color:#565656;
}
}

.about{ line-height: 170%;
    padding: .1rem .15rem;
padding-top: 0rem;

}


.field_cell{
    width: 100%;
    border-bottom: .01rem solid #eee;
    position: relative;
    padding-top: .133333rem;
}

.log_field{
    border: 0;
    width: 100%;
    display: block;
    color: #333;
    font-size: .16rem;
    padding: .12rem 0;
}

.dl{margin-top: 0.3rem;}
.dl1{margin-top: 0.5rem;}
.tip{
  text-align: center;
  margin-top: 0.15rem;margin-bottom: 1rem;
  color:#a6a6a7;
a{color:#444;}
}

.zcbtn{margin-top: 0.2rem;margin-bottom: .3rem;}
</style>